import * as React from "react";
import Ltd from './ltd.png';
import Dressx from './dressx.png';
import Chip from './nfc.png';
import Ar from './ar.png';
import * as styles from './styles.module.css';
import Plx from "react-plx";

const Nfc = (props) => {

  const nfcRef = React.useRef(null);

  React.useEffect(() => {
    props.total(nfcRef.current ? nfcRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={nfcRef} className={`${styles.nfc}`}>
      <div className={`${styles.copy}`}>
        <div className={`${styles.left}`}>
          <img src={Chip} className={`${styles.chip}`} />
          <h2>EMBEDDED NFC CHIP</h2>
          <h5>MERGING THE PHYSICAL AND NFT INTO ONE</h5>
          <p>CULT&RAIN has joined forces with <strong>LTD.INC</strong> to power its NFC chip technology.</p>
          <p>Each DROP 002 hoodie will be embedded with an NFC chip that is paired one-to-one with the NFT –- creating one phygital asset that truly pushes the boundaries of fashion.</p>
          <p>The chip will provide authenticity, proof of ownership, provenance history, and enable access to additional features like exclusive video content, behind-the-scenes production, brand updates, rewards, token-gated access, and much more.</p>
          <p>Soon enough, the CULT&RAIN community will be able to safely buy, sell, and trade phygital wearables through a secure transfer of both the NFT and physical ownership at once.</p>
          <p>Phygital marketplace coming soon... a total <strong>GAME CHANGER</strong>.</p>
          <p><a href="https://ltd.inc/" target="_blank"><img src={Ltd} alt="LTD Inc" height="28" border="0" /></a></p>
        </div>
        <div className={`${styles.right}`}>
          <div><img src={Ar} className={`${styles.ar}`} /></div>
          <h2>AR WEARABLES</h2>
          <h5>RADICALLY ANIMATED VIRTUAL HOODIES</h5>
          <p>CULT&RAIN has also partnered with the largest digital-only fashion platform, DRESSX.</p>
          <p>All DROP 002 holders will be gifted an additional NFT consisting of a 3D AR wearable that breaks the standards of conventional fashion.</p>
          <p>Holders will have the opportunity to try on, style, and trade an animated AR hoodie through a revolutionary virtual experience on DRESSX’s platform.</p>
          <p><a href="https://dressx.com/" target="_blank"><img src={Dressx} alt="Dressx" height="18" border="0" /></a></p>
        </div>
      </div>
    </div>
  );
};

export default Nfc;
