import * as React from "react";
import Plx from "react-plx";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import * as styles from './styles.module.css';

const isBrowser = typeof window !== "undefined";

const Nfts = (props) => {
  const nftRef = React.useRef(null);
  const boxRef = React.useRef(null);
  const nftRef1 = React.useRef(null);
  const nftRef2 = React.useRef(null);
  const nftRef3 = React.useRef(null);
  const nftRef4 = React.useRef(null);
  const nftRef5 = React.useRef(null);
  const nftRef6 = React.useRef(null);
  const nftRef7 = React.useRef(null);
  const nftRef8 = React.useRef(null);
  const nftRef9 = React.useRef(null);

  const [playing, setPlaying] = React.useState(false);
  const [muted, setMuted] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(0);

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const observer = new IntersectionObserver(([entry]) =>
    setIsVisible(entry.isIntersecting)
  );

  const updateScrollPosition = () => {
    if (nftRef.current) {
      if (!isVisible) {
        nftRef1.current.pause();
        nftRef2.current.pause();
        nftRef3.current.pause();
        nftRef4.current.pause();
        nftRef5.current.pause();
        nftRef6.current.pause();
        nftRef7.current.pause();
        nftRef8.current.pause();
        nftRef9.current.pause();
        setPlaying(false);
      } else {
        if (!playing) {
          nftRef1.current.play();
          nftRef2.current.play();
          nftRef3.current.play();
          nftRef4.current.play();
          nftRef5.current.play();
          nftRef6.current.play();
          nftRef7.current.play();
          nftRef8.current.play();
          nftRef9.current.play();
        }
        setPlaying(true);
      }
    }
  };

  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    if (nftRef.current) observer.observe(nftRef.current);
    props.total(nftRef.current ? nftRef.current.clientHeight : 0);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  const properties = {
    autoplay: false,
    indicators: false,
    transitionDuration: 0,
    onChange: (previous, next) => {
      setSlideIndex(next);
    },
    cssClass:`${styles.wrapper}`,
    prevArrow: (
      <div className={`${styles.prevArrow}`}>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M34 25L61.5 49.5L38 74"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
    nextArrow: (
      <div className={`${styles.nextArrow}`}>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M38 25L61.5 49.5L38 74"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
  };

  return (
    <div ref={nftRef} className={`${styles.nft}`}>
      <div className={`${styles.copyLeft}`}>
        <div className={`${styles.copyAbsolute}`}>
          <h2>
            3D NFTs
          </h2>
          <p style={{ opacity: slideIndex == 0 ? 1 : 0 }}>
            <strong>VIRGO 01</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/52
          </p>
          <p style={{ opacity: slideIndex == 1 ? 1 : 0 }}>
            <strong>VIRGO 01</strong>
            <br />
            Sapphire Rarity
            <br />
            Limited Edition 036/52
          </p>
          <p style={{ opacity: slideIndex == 2 ? 1 : 0 }}>
            <strong>VIRGO 02</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/52
          </p>
          <p style={{ opacity: slideIndex == 3 ? 1 : 0 }}>
            <strong>VIRGO 02</strong>
            <br />
            Sapphire Rarity
            <br />
            Limited Edition 036/52
          </p>
          <p style={{ opacity: slideIndex == 4 ? 1 : 0 }}>
            <strong>VIRGO 03</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/52
          </p>
          <p style={{ opacity: slideIndex == 5 ? 1 : 0 }}>
            <strong>VIRGO 03</strong>
            <br />
            Sapphire Rarity
            <br />
            Limited Edition 036/52
          </p>
          <p style={{ opacity: slideIndex == 6 ? 1 : 0 }}>
            <strong>VIRGO 04</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/52
          </p>
          <p style={{ opacity: slideIndex == 7 ? 1 : 0 }}>
            <strong>VIRGO 04</strong>
            <br />
            Sapphire Rarity
            <br />
            Limited Edition 036/52
          </p>
          <p style={{ opacity: slideIndex == 8 ? 1 : 0 }}>
            <strong>THE ONE</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/1
          </p>
        </div>
      </div>
      <div className={`${styles.vid}`}>
        <a
          ref={boxRef}
          className={`${styles.boxContainer}`+(muted ? `` : ` ${styles.boxContainerActive}`)}
          onClick={toggleMuted}
        >
          <div className={`${styles.box} ${styles.box1}`}></div>
          <div className={`${styles.box} ${styles.box2}`}></div>
          <div className={`${styles.box} ${styles.box3}`}></div>
          <div className={`${styles.box} ${styles.box4}`}></div>
          <div className={`${styles.box} ${styles.box5}`}></div>
        </a>
        <Fade {...properties}>
          <video
            ref={nftRef1}
            playsInline
            loop
            muted={slideIndex != 0 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/black_opal_01.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef2}
            playsInline
            loop
            muted={slideIndex != 1 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/black_sapphire_36.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef3}
            playsInline
            loop
            muted={slideIndex != 2 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/bianca_opal.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef4}
            playsInline
            loop
            muted={slideIndex != 3 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/bianca_sapphire.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef5}
            playsInline
            loop
            muted={slideIndex != 4 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/VIRGO_03_PINK_OPAL_001.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef6}
            playsInline
            loop
            muted={slideIndex != 5 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/VIRGO_03_PINK_SAPPHIRE_036.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef7}
            playsInline
            loop
            muted={slideIndex != 6 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/VIRGO_04_BLUE_OPAL_001.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef8}
            playsInline
            loop
            muted={slideIndex != 7 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/VIRGO_04_BLUE_SAPPHIRE_036.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nftRef9}
            playsInline
            loop
            muted={slideIndex != 8 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop002/THE_ONE.mp4"
              type="video/mp4"
            />
          </video>
        </Fade>
      </div>
      <div className={`${styles.copyRight} ${styles.hideMobile}`}>
        <div className={`${styles.copyAbsolute}`}>
          <h2>
            FULLY
            <br />
            ANIMATED
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Nfts;