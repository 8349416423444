import * as React from "react";
import * as styles from './styles.module.css';
import Plx from "react-plx";

const DropIntro = (props) => {

  let observer = null;

  const introRef = React.useRef(null);
  const videoRef = React.useRef(null);

  const [playing, setPlaying] = React.useState(false);

  let h3DataA=[
    {
      start: props.start,
      end: props.start + (props.height*2),
      properties: [
        {
          startValue: (props.orientation=='portrait'?30:20),
          endValue: (props.orientation=='portrait'?0:0),
          property: "top",
          unit: "%",
        },
      ],
    },
  ];

  let h3DataB=[
    {
      animateWhenNotInViewport:true,
      start: props.start,
      end: props.start + (props.height*2),
      properties: [
        {
          startValue: 0,
          endValue: (props.orientation=='portrait'?70:30),
          property: "top",
          unit: "%",
        },
      ],
    },
  ];

  let copyData=[
    {
      start: props.start,
      end: props.start + (props.height*2),
      properties: [
        {
          startValue: (props.orientation=='portrait'?20:10),
          endValue: (props.orientation=='portrait'?40:30),
          property: "top",
          unit: "%",
        },
      ],
    },
  ];

  let videoData=[
    {
      start: props.start + (props.height*(props.orientation=='portrait'?1:2)),
      end: props.start + (props.height*(props.orientation=='portrait'?2:4)),
      properties: [
        {
          startValue: 30,
          endValue: 100,
          property: "width",
          unit:"%"
        },
      ],
    },
    {
      start: props.start + (props.height*(props.orientation=='portrait'?1:2)),
      end: props.start + (props.height*(props.orientation=='portrait'?2:4)),
      properties: [
        {
          startValue: (props.orientation=='portrait'?5:20),
          endValue: 0,
          property: "right",
          unit: "%",
        },
      ],
    },
    {
      start: props.start + (props.height*(props.orientation=='portrait'?1:2)),
      end: props.start + (props.height*(props.orientation=='portrait'?2:4)),
      properties: [
        {
          startValue: 45,
          endValue: 0,
          property: "bottom",
          unit:"%"
        },
      ],
    },
  ];

  React.useEffect(() => {
    props.total(introRef.current ? introRef.current.clientHeight : 0);
    if (videoRef.current&&videoRef.current.element) {
      /*observer = new IntersectionObserver(([entry]) => {
        if(entry.isIntersecting){
          if (!playing) {
            videoRef.current.element.play();
            setPlaying(true);
          }else{
            videoRef.current.element.pause();
            setPlaying(false);
          }
        }
      });
      observer.observe(introRef.current);*/
    }
    return () => {};
  });

  return (
    <div ref={introRef} className={styles.intro}>
      <Plx tagName="video"
        parallaxData={videoData}
        playsInline
        loop
        className={`${styles.video}`}
        muted
        autoPlay
        ref={videoRef}
      >
        <source src="https://cdn.cultandrain.com/drop002/mini_trailer.mp4" type="video/mp4" />
      </Plx>
      <Plx tagName="h3" parallaxData={h3DataA} className={`${styles.h3} ${styles.right}`}>DROP <span>002</span></Plx>
      <Plx tagName="h3" parallaxData={h3DataB} className={`${styles.h3}`}>002</Plx>
      <Plx parallaxData={copyData} className={`${styles.copy}`}>
        <h2 className={`${styles.h2}`}>VIRGO<strong className={`${styles.strong}`}>HOODIE</strong></h2>
        <p>Our latest phygital collection introduces an entirely new frontier in fashion with exclusive luxury physicals tied to digital assets living on the blockchain.</p>
        <p><strong>209 3D animated NFTs</strong> matched with <strong>209 luxury NFC-chipped physical sweatshirts</strong> that expands the CULT&RAIN wardrobe.</p>
        <p><strong>Cutting-Edge Innovation</strong><br />DROP 002 brings together three novel pillars of technology – <strong>AI</strong> (artificial intelligence), <strong>AR</strong> (augmented reality), and <strong>NFC</strong> (near-field communication).</p>
        <p><strong>Limited DROP 002 Edition</strong><br />1 sweatshirt style<br />4 unique colorways<br />4 AI-generated print designs<br />4 core logo print designs<br />4 radical AR wearables</p>
        <p><strong>Highest Quality</strong><br />“MADE IN LA” luxury, sourcing the finest materials and fabrication through our coveted supply chains in the USA.</p>
      </Plx>
    </div>
  );
};

export default DropIntro;
