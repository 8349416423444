import * as React from "react";
import * as styles from './styles.module.css';
const Access = (props) => {

  const accessRef = React.useRef(null);

  React.useEffect(() => {
    props.total(accessRef.current ? accessRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={accessRef} className={`${styles.access}`}>
      <div className={`${styles.spaceship}`}><img src="https://cdn.cultandrain.com/drop002_spaceship_1800.jpg" /></div>
      <div className={styles.copy}>
        <div className={styles.right}>
          <h2>PRE-SALE ACCESS</h2>
          <p><strong>COMMUNITY IS OUR CORE</strong></p>
          <p>CULT&RAIN holders have VIP first access to mint the DROP 002 collection.</p>
        </div>
        <div className={styles.left}>
          <h2>PUBLIC RELEASE</h2>
          <p><strong>BUILDING OUR FOLLOWING</strong></p>
          <p>It's time for the world to experience CULT&RAIN’s groundbreaking luxurious phygital fashion.</p>
          <p>Join our <a href="http://discord.gg/cultandrain">Discord</a> and follow our <a href="https://twitter.com/cultandrain">Twitter</a> for more information.</p>
        </div>
      </div>

    </div>
  );
};

export default Access;
