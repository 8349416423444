// extracted by mini-css-extract-plugin
export var intro = "styles-module--intro--6bgus";
export var h3 = "styles-module--h3--vp6F6";
export var reveal = "styles-module--reveal--TOXP+";
export var right = "styles-module--right--9-RcH";
export var h2 = "styles-module--h2--CbHcz";
export var strong = "styles-module--strong--GfwAm";
export var copy = "styles-module--copy--BxXqc";
export var video = "styles-module--video--lQ9hR";
export var access = "styles-module--access--wXvg-";
export var spaceship = "styles-module--spaceship--GaC8t";
export var left = "styles-module--left--HF3Fc";
export var cultr = "styles-module--cultr--UWVYj";
export var pulse = "styles-module--pulse--n06VE";
export var nfc = "styles-module--nfc--qLU1o";
export var section_right = "styles-module--section_right--sKUNj";
export var section_left = "styles-module--section_left--k+FFp";
export var header = "styles-module--header--FKJOa";
export var bottom = "styles-module--bottom--Cb0Cc";
export var top = "styles-module--top--a97d2";
export var img = "styles-module--img--Z8hrI";
export var nft = "styles-module--nft--xOBML";
export var wrapper = "styles-module--wrapper--j0QcS";
export var copyLeft = "styles-module--copy-left--ZJwxq";
export var copyRight = "styles-module--copy-right--5gCZs";
export var vid = "styles-module--vid--vJpoQ";
export var copyAbsolute = "styles-module--copy-absolute--I2OOn";
export var nextArrow = "styles-module--next-arrow--T3XXf";
export var prevArrow = "styles-module--prev-arrow--oXWa7";
export var boxContainer = "styles-module--boxContainer--9a2Q9";
export var boxContainerActive = "styles-module--boxContainerActive--bwoCr";
export var box = "styles-module--box--TFP15";
export var box1 = "styles-module--box1--am0Ty";
export var quiet = "styles-module--quiet--XI0YJ";
export var box2 = "styles-module--box2--2U4I6";
export var normal = "styles-module--normal--pWHS8";
export var box3 = "styles-module--box3--n9hAs";
export var box4 = "styles-module--box4--uwpHB";
export var loud = "styles-module--loud--+lg1G";
export var box5 = "styles-module--box5--RnVS5";
export var hideDesktop = "styles-module--hide-desktop--ToUhJ";