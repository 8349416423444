import * as React from "react";
import * as styles from './styles.module.css';
import Plx from "react-plx";

const Reveal = (props) => {

  const revealRef = React.useRef(null);

  React.useEffect(() => {
    props.total(revealRef.current ? revealRef.current.clientHeight : 0);
    return () => {};
  });

  let h3DataB=[
    {
      animateWhenNotInViewport:false,
      start: props.start,
      end: props.start + (props.height*1),
      properties: [
        {
          startValue: 0,
          endValue: 30,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];

  return (
    <div ref={revealRef} className={`${styles.reveal}`}>
      <div className={`${styles.section_right}`}>
        <video
          playsInline
          loop
          muted
          autoPlay
        >
          <source src="https://cdn.cultandrain.com/drop002/hoodie_generic.mp4" type="video/mp4" />
        </video>
        <h2 className={`${styles.header}`}>VIRGO #01</h2>
      </div>
      <div className={`${styles.section_left}`}>
        <video
            playsInline
            loop
            muted
            autoPlay
          >
            <source src="https://cdn.cultandrain.com/drop002/hoodie_bianco.mp4" type="video/mp4" />
          </video>
          <h2 className={`${styles.header}`}>VIRGO #02</h2>
      </div>
      <div className={`${styles.section_right}`}>
        <video
            playsInline
            loop
            muted
            autoPlay
          >
            <source src="https://cdn.cultandrain.com/drop002/DOUBLE_PINK.mp4" type="video/mp4" />
          </video>
          <h2 className={`${styles.header}`}>VIRGO #03</h2>
      </div>
      <div className={`${styles.section_left}`}>
        <video
            playsInline
            loop
            muted
            autoPlay
          >
            <source src="https://cdn.cultandrain.com/drop002/DOUBLE_AZZURRO.mp4" type="video/mp4" />
          </video>
          <h2 className={`${styles.header}`}>VIRGO #04</h2>
      </div>
      <h3 className={`${styles.h3}`}>002</h3>
    </div>
  );
};

export default Reveal;
