import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/genesis.css";
import Loader from "../components/loader.js";
import Icons from "../components/icons.js";
import Intro from "../components/intro.js";
import Mint from "../components/drop002/mint.js";
import DropIntro from "../components/drop002/intro.js";
import Reveal from "../components/drop002/reveal.js";
import Nfc from "../components/drop002/nfc.js";
import Nfts from "../components/drop002/nfts.js";
import Cultr from "../components/drop002/cultr.js";
import Access from "../components/drop002/access.js";
import Teaser from "../components/genesis/teaser.js";
import Discover from "../components/drop002/discover.js";
import CultureRain from "../components/culturerain.js";
import Footer from "../components/footer.js";
import Join from "../components/join.js";

var Scroll = require("react-scroll");
var scroll = Scroll.animateScroll;

const isBrowser = typeof window !== "undefined";

// markup
const IndexPage = () => {
  const bypassTeaser = false;

  const endRef = React.useRef(null);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const [isVideoSent, setIsVideoSent] = React.useState(false);
  const [teaserSrc, setTeaserSrc] = React.useState(null);
  const [orientation, setOrientation] = React.useState("landscape");

  const onLoadedData = () => {
    //setIsVideoLoaded(true);
  };

  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = React.useState(
    isBrowser ? window.innerHeight : 0
  );

  const [footerVisible, setFooterVisible] = React.useState(false);
  const [mintVisible, setMintVisible] = React.useState(true);

  const [teaserHeight, setTeaserHeight] = React.useState(0);
  const [introHeight, setIntroHeight] = React.useState(0);
  const [revealHeight, setRevealHeight] = React.useState(0);
  const [nfcHeight, setNfcHeight] = React.useState(0);
  const [nftsHeight, setNftsHeight] = React.useState(0);
  const [cultrHeight, setCultrHeight] = React.useState(0);
  const [accessHeight, setAccessHeight] = React.useState(0);
  const [discoverHeight, setDiscoverHeight] = React.useState(0);
  const [cultureHeight, setCultureHeight] = React.useState(0);
  const [joinHeight, setJoinHeight] = React.useState(0);

  const [muted, setMuted] = React.useState(true);
  const [active, setActive] = React.useState(true);

  const boxRef = React.useRef(null);

  const updateWidthAndHeight = () => {
    setWidth(isBrowser ? window.innerWidth : 0);
    setHeight(isBrowser ? window.innerHeight : 0);
    if (bypassTeaser) setTeaserHeight(-(height * 0.2));
    else setTeaserHeight(height * 1.2);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const updateScrollPosition = () => {
    if (!boxRef.current) return;
    if ((isBrowser ? window.scrollY : 800) > height * 2 - height / 2) {
      boxRef.current.style.display = "none";
    } else {
      boxRef.current.style.display = "flex";
    }
  };

  const setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };
  const onLoadEnd = () => {
    setIsVideoLoaded(true);
  };
  React.useEffect(() => {
    setTeaserSrc(
      "https://cdn.cultandrain.com/drop002/CR_Hoodie_230418a_24mb.mp4"
    );
    if (bypassTeaser) setIsVideoLoaded(true);
    if (!bypassTeaser && !hasScrolled && endRef.current) {
      setHasScrolled(true);
      setTimeout(() => {
        scroll.scrollTo(endRef.current.getBoundingClientRect().top - 1, {
          duration: 7000,
          delay: 0,
        });
      }, 0);
    }

    setScreenOrientation();
    updateWidthAndHeight();
    if (isBrowser) window.addEventListener("resize", updateWidthAndHeight);
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    if (isBrowser)
      window.addEventListener("orientationchange", setScreenOrientation);

    return () => {
      if (isBrowser) window.removeEventListener("resize", updateWidthAndHeight);
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
      if (isBrowser)
        window.removeEventListener("orientationchange", setScreenOrientation);
    };
  });
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CULT&amp;RAIN™</title>
        <link rel="canonical" href="https://www.cultandrain.com/" />
        <meta
          name="description"
          content="Luxury fashion born from Web3, merging NFTs with exclusive physical redeemables."
        />
      </Helmet>
      {isBrowser && (
        <div className="genesisPage">
          <div className="wrapper">
            {!isVideoLoaded && <Loader />}
            {isVideoLoaded && (
              <div>
                <Icons
                  footerVisible={footerVisible}
                  mintVisible={mintVisible}
                  page="home"
                />
                {!bypassTeaser && (
                  <a
                    ref={boxRef}
                    className={
                      "boxContainer" + (muted ? "" : " boxContainerActive")
                    }
                    onClick={toggleMuted}
                  >
                    <div className="box box1"></div>
                    <div className="box box2"></div>
                    <div className="box box3"></div>
                    <div className="box box4"></div>
                    <div className="box box5"></div>
                  </a>
                )}
              </div>
            )}

            {!bypassTeaser && <Intro height={height} landing={true} />}
            <div
              className="page"
              style={{ paddingTop: bypassTeaser ? 0 : height + height / 2 }}
            >
              {bypassTeaser ? (
                <Mint setMintVisible={setMintVisible} />
              ) : (
                <Teaser
                  height={height}
                  muted={muted}
                  teaserSrc={teaserSrc}
                  landing={true}
                  onLoadEnd={onLoadEnd}
                />
              )}
              <div ref={endRef} />
              {isVideoLoaded && (
                <div>
                  <DropIntro
                    start={teaserHeight}
                    width={width}
                    height={height}
                    total={setIntroHeight}
                    orientation={orientation}
                  />
                  <Reveal
                    start={teaserHeight+introHeight}
                    height={height}
                    total={setRevealHeight}
                    orientation={orientation}
                  />
                  <Nfc
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight
                    }
                    height={height}
                    total={setNfcHeight}
                    orientation={orientation}
                  />
                  <Discover
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight
                    }
                    height={height}
                    total={setDiscoverHeight}
                  />
                  <Nfts
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight +
                      discoverHeight
                    }
                    height={height}
                    total={setNftsHeight}
                  />
                  <Cultr
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight +
                      discoverHeight +
                      nftsHeight
                    }
                    height={height}
                    total={setCultrHeight}
                    orientation={orientation}
                  />
                  <CultureRain
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight +
                      discoverHeight +
                      nftsHeight +
                      cultrHeight
                    }
                    height={height}
                    total={setCultureHeight}
                    orientation={orientation}
                  />
                  <Access
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight +
                      discoverHeight +
                      nftsHeight +
                      cultrHeight +
                      cultureHeight
                    }
                    height={height}
                    total={setAccessHeight}
                    orientation={orientation}
                  />

                  <Join start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight +
                      discoverHeight +
                      nftsHeight +
                      cultrHeight +
                      cultureHeight +
                      accessHeight
                    }
                    height={height} total={setJoinHeight}
                  />
                  <Footer
                    start={
                      teaserHeight +
                      introHeight +
                      revealHeight +
                      nfcHeight +
                      discoverHeight +
                      nftsHeight +
                      cultrHeight +
                      cultureHeight +
                      accessHeight +
                      joinHeight
                    }
                    height={height}
                    setFooterVisible={setFooterVisible}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <form
        style={{ display: "none" }}
        name="Join"
        method="POST"
        data-netlify="true"
      >
        <input type="email" name="email" />
      </form>
    </main>
  );
};

export default IndexPage;
