import * as React from "react";
import * as styles from './styles.module.css';
const Cultr = (props) => {

  const cultrRef = React.useRef(null);

  React.useEffect(() => {
    props.total(cultrRef.current ? cultrRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={cultrRef} className={`${styles.cultr}`}>
      <img src="https://cdn.cultandrain.com/drop002_character_1800.jpg" />
      <div className={`${styles.copy}`}>
        <h2>UTILITIES</h2>
        <h5>WE VALUE COMMUNITY FIRST</h5>
        <p>Every NFT minted includes rarity attributes and one of a kind redeemables<br />such as brand discounts, exclusive airdrops, and VIP access into the<br />CULT&RAIN ecosystem.</p>
        <h3>INTRODUCING THE<br />ULTIMATE<br /><strong>RARITY SCALE</strong></h3>
        <p>To learn more about our DROP 002 UTILITIES please click below.</p>
        <p>&nbsp;</p><p><a href="/cultr-pass-drop002">LEARN MORE</a></p>
      </div>

    </div>
  );
};

export default Cultr;
